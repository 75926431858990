import {
  Routes,
  Route,
  Navigate,
  RouteProps,
  RoutesProps,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { useSelector } from "react-redux";

const Navigation = () => {
  let navigate = useNavigate();

  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };
  const Login = React.lazy(() => import("../components/Login/Login"));
  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const ResetLinkSent = React.lazy(
    () => import("../components/Login/ResetLinkSent")
  );
  const Otp = React.lazy(() => import("../components/Login/Otp"));
  const OtpSuccess = React.lazy(() => import("../components/Login/OtpSuccess"));
  const Signup = React.lazy(() => import("../components/Signup/Signup"));
  const EmailVerif = React.lazy(
    () => import("../components/Signup/EmailVerif")
  );
  const EmailVerifSuccess = React.lazy(
    () => import("../components/Signup/EmailVerifSuccess")
  );
  const Plans = React.lazy(() => import("../components/Plans/Plans"));
  const QandA = React.lazy(() => import("../components/QA/QA"));
  const ChangePassword = React.lazy(
    () => import("../components/Settings/ChangePassword")
  );
  const EmailCheck = React.lazy(
    () => import("../components/Signup/VerifyEmail")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const FirstLogin = React.lazy(
    () => import("../components/FirstLogin/FirstLogin")
  );
  const FirstLoginQA = React.lazy(
    () => import("../components/FirstLogin/FirstLoginQA")
  );

  const Files = React.lazy(() => import("../components/Files/Files"));
  const Document = React.lazy(() => import("../components/Files/Document"));
  const MyQuestionnaire = React.lazy(
    () => import("../pages/Files/MyQuestionnaire")
  );
  const AccessMangement = React.lazy(
    () => import("../components/Access/AccessMangement")
  );
  const TrackChanges = React.lazy(
    () => import("../components/TrackChanges/ActivityLog")
  );
  const WhatsNew = React.lazy(() => import("../components/WhatsNew/WhatsNew"));
  const Support = React.lazy(() => import("../components/Support/Support"));
  const Settings = React.lazy(() => import("../components/Settings/Settings"));
  const Subscription = React.lazy(
    () => import("../components/Settings/Subscription")
  );
  const ChatBot = React.lazy(() => import("../pages/ChatBot/ChatBot"));
  const ResetPassword = React.lazy(
    () => import("../components/Login/ResetPassword")
  );
  const CheckEmailForForgetPassword = React.lazy(
    () => import("../components/Login/VerifyEmailForForgetPassword")
  );
  const profileDetailsFromRedux = useSelector(
    (state: any) => state?.profile?.profile_setting
  );
  // useEffect(() => {
  //   if (Object.keys(profileDetailsFromRedux).length === 0) {
  //     navigate("/login");
  //   }
  //   if (
  //     localStorage.getItem("access_token") === "" ||
  //     localStorage.getItem("access_token") === undefined
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);
  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/link-sent"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetLinkSent />{" "}
              </Suspense>
            }
          />
          <Route
            path="/signup"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Signup />{" "}
              </Suspense>
            }
          />
          <Route
            path="/email-verification"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <EmailVerif />{" "}
              </Suspense>
            }
          />
          <Route
            path="/verify-email/forget-password/:url"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <CheckEmailForForgetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/verify-email/:url"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <EmailCheck />{" "}
              </Suspense>
            }
          />
          <Route
            path="/email-verification-success"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <EmailVerifSuccess />{" "}
              </Suspense>
            }
          />
          <Route
            path="/plans"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Plans />{" "}
              </Suspense>
            }
          />
          <Route
            path="/otp"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Otp />{" "}
              </Suspense>
            }
          />
          <Route
            path="/otp-success"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <OtpSuccess />{" "}
              </Suspense>
            }
          />

          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Dashboard />{" "}
                </Suspense>
              }
            />
             <Route
              path="/"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Dashboard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/first-login"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <FirstLogin />{" "}
                </Suspense>
              }
            />
            <Route
              path="/first-login-qa"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <FirstLoginQA />{" "}
                </Suspense>
              }
            />

            <Route
              path="/q-and-a"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <QandA />{" "}
                </Suspense>
              }
            />
            <Route
              path="/files"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Files />{" "}
                </Suspense>
              }
            />
            <Route
              path="/document"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Document />{" "}
                </Suspense>
              }
            />
            <Route
              path="/my-questionnaire"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <MyQuestionnaire />{" "}
                </Suspense>
              }
            />
            <Route
              path="/access-mangement"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AccessMangement />{" "}
                </Suspense>
              }
            />
            <Route
              path="/track-changes"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <TrackChanges />{" "}
                </Suspense>
              }
            />
            <Route
              path="/whats-new"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <WhatsNew />{" "}
                </Suspense>
              }
            />
            <Route
              path="/support"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Support />{" "}
                </Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Settings />{" "}
                </Suspense>
              }
            />
            <Route
              path="/change-password"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ChangePassword />{" "}
                </Suspense>
              }
            />
            <Route
              path="/subscription"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Subscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="/chat-bot"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ChatBot />{" "}
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
