import { legacy_createStore, combineReducers, applyMiddleware } from "redux";

import { profileReducer } from "./profileReducer";
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
const reducers = combineReducers({
  profile:profileReducer
});

const persistConfig = {
key: 'root',
storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
export default store;

export type RootState = ReturnType<typeof store.getState>;
